<template>
  <div class="wrap-content">
    <slot name="header">
      <v-page-header :title="title">
        <div class="text-right ml-auto flex items-center">
          <v-button
            v-if="appHasNewVersion"
            icon-left="bell"
            class="btn--secondary mr-5"
            @click.prevent="requestAppRefreshefresh"
          >
            <strong>New version available!</strong> Click to update.
          </v-button>
          <v-button class="btn--secondary mr-5">
            14 days left in Pro Plan
          </v-button>

          <v-dropdown
            x-placement="top"
            class="mr-5 dropdown-notifications"
          >
            <v-icon
              slot="label"
              icon="bell"
            />
            <div class="w-full">
              TODO
              <v-dropdown-item>zz</v-dropdown-item>
              <v-dropdown-item>aabc</v-dropdown-item>
            </div>
          </v-dropdown>

          <router-link
            :to="{ name: 'manage.company'}"
            class="company-name"
          >
            {{ companyName || 'Company' }}
          </router-link>
        </div>
      </v-page-header>
    </slot>
    <slot />
  </div>
</template>
<style scoped>
  .company-name {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
</style>
<script>
import EventBus from '@/bus'

export default {
  name: 'ContentWrap',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    appHasNewVersion() {
      return this.$store.state.hasNewUpdate
    },
    companyName() {
      return this.$store.getters['company/name']
    }
  },
  methods: {
    requestAppRefreshefresh() {
      EventBus.$emit('requestAppRefresh')
    }
  }
}
</script>
